import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { CrmSafeAny } from 'common-module/core/types';
import {
  CrmEndpoint,
  CrmEndpointDecorator,
  CrmEndpointListResponse,
} from 'common-module/endpoint';
import { Observable } from 'rxjs';

import { JournalModel } from './journal.model';

@Injectable({ providedIn: 'root' })
export class JournalApiService {
  @CrmEndpointDecorator({
    configName: 'crm',
    endpointName: 'journal',
  })
  private readonly endpoint!: CrmEndpoint<CrmSafeAny>;

  list<Model extends JournalModel>(params: Params) {
    return this.endpoint.list({ params }) as Observable<
      CrmEndpointListResponse<Model>
    >;
  }

  listAll<Model extends JournalModel>(params: Params) {
    return this.endpoint.listAll({ params }) as Observable<Model[]>;
  }

  logError<Model extends JournalModel, Error>(body: Error): Observable<Model> {
    return this.endpoint.request('POST', 'frontend', { body });
  }
}
